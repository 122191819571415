<template></template>

<script>
import { removeStorage } from "@/utils/storage";
export default {
  mounted() {
    removeStorage("token");
    this.$store.commit("userNotAuthed");
    this.$router.push({
      name: 'IndexPage',
    });
  },
};
</script>

<style>
</style>